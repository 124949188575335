import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query NotFoundPage {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      comingSoon
      email
      author
      keywords
    }

    festival: sanityYear(title: {eq: "2022"}) {
      _rawIntroduction
      _rawComingSoon
      _rawCall
      title
      beginDate
      endDate
      horizontalLogo {
        ...SanityImage
        alt
      }
      stackedLogo {
        ...SanityImage
        alt
      }
      siteImage {
        ...SanityImage
        alt
      }
      colorA
      colorB
      colorC
      bodyFont
      secondaryFont
      links {
        title
        url
      }
      partners {
        title
        url
        image {
          ...SanityImage
          alt
        }
      }
    }

  }
`

const NotFoundPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const festival = (data || {}).festival

  return (
    <Layout 
        title={site.title} 
        email={site.email} 
        footer={site.footer} 
        partners={festival.partners} 
        colorA={festival.colorA} 
        colorB={festival.colorB} 
        colorC={festival.colorC} 
        bodyFont={festival.bodyFont} 
        secondaryFont={festival.secondaryFont} 
        horizontalLogo={festival.horizontalLogo}
        instagramLink={site.instagramLink} 
        facebookLink={site.facebookLink} 
        twitter={site.twitter} 
        linkedInLink={site.linkedInLink} >
        <SEO
          title={site.title}
          author={site.author}
          description={site.description}
          keywords={site.keywords}
          image={festival.siteImage}
        />
      <Container>
        
        <div className="wrapper">
          <div id="festival-fourohfour-inner">
          <h3>NOT FOUND</h3>
          <p>You hit a route that doesn&#39;t exist.</p>
          </div>
        </div>

      </Container>
    </Layout>
  )
}

export default NotFoundPage
